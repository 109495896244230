<template>
    <button @click="this.OpenPreview" class="library-card">
        <img loading="lazy" decoding="async" v-if="imageExists(assessement.name)" :src="imagePath" alt="logo" />
        <font-awesome-icon v-else class="card-icon" :icon="assessement.icon ? assessement.icon : ['fas', 'file-invoice']" />
        <!-- <font-awesome-icon :icon="['fas', 'file-invoice']" /> -->
        <span class="assessTitle">{{ transformedName }}</span>
        <font-awesome-icon @click.stop="$emit('delete')" :icon="['far', 'circle-xmark']" class="delete-btn" v-if="assessement.delete && this.$route.path.includes('/library')" />
    </button>
</template>

<script>
export default {
    name: "LibraryCard",
    props: {
        OpenPreview: { type: Function },
        assessement: { type: Object } /* handleDelete:{type: Function}, */,
        // imagePath: { type: String },
    },
    data() {
        return {
            imagePath: "",
        };
    },
    computed: {
        transformedName() {
            // Use the same transformation logic as before
            const nameArray = this.assessement?.name?.split(/-/);

            // Capitalize the first letter of each word and remove "-"
            const transformedArray = nameArray?.map((part) => {
                const words = part.split(/\s+/);
                return words
                    .map((word) => {
                        const capitalWords = ["KPMG", "DRAGNET", "GTCO", "NNPC", "PWC", "ZENITHBANK", "xml", "aws", "vba"];

                        // Capitalize the entire word if it's in the list
                        if (capitalWords.includes(word.toUpperCase())) {
                            return word.toUpperCase();
                        }
                        // Capitalize the first letter of each word
                        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
                    })
                    ?.join(" ");
            });

            // Join the parts into a sentence
            return transformedArray?.join(" ");
        },
    },
    methods: {
        imageExists(name) {
            try {
                if (name.toLowerCase().includes("google-cloud")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/google-cloud.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("intel") && !name.toLowerCase().includes("intelligence")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/intel.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("analytics")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/google_analytics.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("google-ads")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/google-ads.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("kpmg")) {
                    // Use a different image path if 'name' includes 'special'
                    // console.log("KPMG");
                    this.imagePath = require("@/assets/assessmentsLogos/KPMG.svg");
                    return true;
                }
                if (name.toLowerCase().includes("final-cut-pro")) {
                    // Use a different image path if 'name' includes 'special'
                    // console.log("KPMG");
                    this.imagePath = require("@/assets/assessmentsLogos/final-cut-pro.svg");
                    return true;
                }
                if (name.toLowerCase().includes("workforce")) {
                    // Use a different image path if 'name' includes 'special'
                    // console.log("KPMG");
                    this.imagePath = require("@/assets/assessmentsLogos/workforce.svg");
                    return true;
                }
                if (name.toLowerCase().includes("nnpc")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/NNPC.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("nvidia")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/Nvidia.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("oracle")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/oracle.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("pwc")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/PWC.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("gtco")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/GTCO.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("zenith bank")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/ZenithBank.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("dragnet")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/dragnet.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("at&t")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/AT&T.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("amazon")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/amazon.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("aws")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/aws.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("deloitte")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/Deloitte.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("accenture")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/Accenture.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("photoshop")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/adobe-photoshop.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("adobe-in-design")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/adobe-in-design.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("adobe-xd")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/adobe-xd.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("adobe-lightroom")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/adobe-lightroom.svg`);
                    return true;
                }

                if (name.toLowerCase().includes("adobe-ai") || name.toLowerCase().includes("adobe-illustrator")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/adobe-illustrator.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("adobe-acrobat")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/adobe-acrobat.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("adobe")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/Adobe.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("adp")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/ADP.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("aig")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/AIG.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("alcatel")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/alcatel.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("amdocs")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/amdocs.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("bayer")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/Bayer.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("blackrock")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/BlackRock.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("capgemini")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/Capgemini.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("cisco")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/Cisco.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("cognizant")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/Cognizant.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("cyient")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/Cyient.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("dell")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/Dell.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("ericsson")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/Ericsson.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("sutherland")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/eversheds-sutherland.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("eversheds-sutherland")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/eversheds-sutherland.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("expedia")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/Expedia.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("google")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/Google.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("hackerrank")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/hackerrank.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("hcl")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/HCL_Technologies.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("hp")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/hp.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("hsbc")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/hsbc.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("huawei")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/Huawei.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("ibm")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/ibm.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("infosys")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/Infosys.svg`);
                    return true;
                }

                if (name.toLowerCase().includes("mindtree")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/mindtree.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("quest")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/QuestGlobal.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("global")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/QuestGlobal.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("reuters")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/reuters.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("sap")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/sap.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("sony")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/Sony.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("visa")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/Visa.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("vmware")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/VMware.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("wipro")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/Wipro.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("zte")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/zte.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("dragnet")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/dragnet.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("deloitte")) {
                    this.imagePath = require(`@/assets/landing-page-logos/deloitte.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("gtco")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/GTCO.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("/")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/Error Data Checking.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("verbal")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/Verbal.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("customer service")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/Customer Service.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("motivation")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/Motivation.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("entrepreneurship")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/Entrepreneurship.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("time management")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/Time Management.svg`);
                    return true;
                }

                // Use a dynamic import to check if the image file exists
                this.imagePath = require(`@/assets/assessmentsLogos/${name}.svg`);
                return true;
            } catch (error) {
                return false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.library-card {
    // position: relative;
    display: flex;
    align-items: center;
    background: #fff;
    border-radius: 20px;
    padding: 1.5rem 2rem;
    font-family: "Roboto";
    font-weight: 400;
    font-size: 18px;
    color: #4d5e80;
    text-align: center;
    justify-content: flex-start;
    gap: 0.5rem;
    border: none;
    box-shadow: 0px 0px 5px 2px rgba(116, 116, 116, 0.1);
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    text-align: start;

    span {
        font-size: 18px;
        font-weight: 500;
        margin-left: 2%;
        line-height: 140%;
    }

    &:hover {
        transform: scale(1.05);

        .delete-btn {
            opacity: 1;
        }
    }

    .delete-btn {
        position: absolute;
        top: -6px;
        right: -6px;
        color: #2196f3;
        opacity: 0;
        transition: all ease 0.3s;
        font-size: 24px;
        font-weight: 700;
    }
}

img {
    margin-right: 8px;
    height: 45px;
    width: 45px;
}

.card-icon {
    font-size: 45px;
    color: #2196f3;
}
</style>
