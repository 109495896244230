<template>
    <div class="the-board" :id="id" @dragover.prevent @drop.prevent="drop">
        <div class="board-title">
            <span :id="title">{{ title }}</span>
            <button>
                <img loading="lazy" decoding="async" src="../assets/Images/icons/icon_dots.svg" width="25" height="25" />
            </button>
            <button>
                <img loading="lazy" decoding="async" src="../assets/Images/icons/icon_plus.svg" width="25" height="25" />
            </button>
        </div>
        <div class="cards"><slot> </slot></div>
    </div>
</template>

<script>
export default {
    name: "KanbanBoard",
    props: ["id", "title", "borderColor"],
    methods: {
        drop: (e) => {
            const card_id = e.dataTransfer.getData("card_id");
            const card = document.getElementById(card_id);
            const cards = e.currentTarget.querySelector(".cards");
            if (e.target.classList.contains("the-board") || e.target.classList.contains("board-title") || e.target.classList.contains("task-card") || e.target.classList.contains("cards")) {
                cards.appendChild(card);
                card.style.display = "block";
            } else {
                card.style.display = "block";
            }
        },
    },
    mounted() {
        const title = document.getElementById(this.title);
        title.style.setProperty("--color", this.borderColor);
    },
};
</script>

<style lang="scss" scoped>
.the-board {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex: 1;
    padding-top: 2%;

    .board-title {
        background: #fff;
        font-weight: 900;
        font-size: 12px;
        line-height: 20px;
        color: #4d5e80;
        display: flex;
        flex-direction: row;
        justify-content: end;
        align-items: center;
        padding: 5% 0;
        box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
        border-radius: 5px;
        > :first-child {
            flex: 1 0 50%;
            gap: 1rem;
            display: flex;
            &::before {
                content: "";
                width: 3px;
                background: var(--color);
                border-radius: 25px;
            }
        }
        button {
            flex: 1 0 20%;
            border: none;
            background: none;
        }
    }
    .cards {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }
}
</style>
