<template>
    <div class="top-assessement w-full">
        <div class="relative h-full topAssess">
            <h2 class="title">{{ assessement.name }}</h2>
            <div>
                <p class="assessDesc">{{ getFirstPhrase(assessement.description_test) }}...</p>
            </div>
            <div class="mt-[8%] absolute bottom-0 w-full">
                <div class="flex items-center mb-3 time mx-3">
                    <font-awesome-icon :icon="['far', 'clock']" class="mx-2" />
                    {{ assessement?.questions_nbr > 25 ? parseInt((20 * 35) / 60) : parseInt((assessement?.questions_nbr * 35) / 60) }} minutes
                </div>
                <div class="w-[90%] mx-[5%] flex justify-between items-center btnWrapper">
                    <button class="selectBtn details" @click="navigateToPreview"><font-awesome-icon :icon="['far', 'eye']" /></button>
                    <button class="add-now" @click="$emit('openPreview')">{{ $t("Details") }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "LibraryAssessCard",
    props: ["assessement", "addAssessment"],
    data() {
        return {
            imagePath: "",
        };
    },
    methods: {
        getFirstPhrase(description) {
            // Split the string into an array of words
            let words = description?.split(/\s+/);

            // Extract the first 200 words
            let first200Words = words?.slice(0, 30)?.join(" ");
            return first200Words;
        },
        navigateToPreview() {
            const url = this.$router.resolve({
                path: "/previewAssessment",
                query: { id: this.assessement._id },
            }).href;

            // Open the route in a new tab
            window.open(url, "_blank");
        },
        imageExists(name) {
            try {
                if (name?.toLowerCase().includes("kpmg")) {
                    // Use a different image path if 'name' includes 'special'
                    // console.log("KPMG");
                    this.imagePath = require("@/assets/assessmentsLogos/KPMG.svg");
                    return true;
                }
                if (name?.toLowerCase().includes("workforce")) {
                    // Use a different image path if 'name' includes 'special'
                    // console.log("KPMG");
                    this.imagePath = require("@/assets/assessmentsLogos/workforce.png");
                    return true;
                }
                if (name?.toLowerCase().includes("bbc")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/BBC.png`);
                    return true;
                }
                if (name?.toLowerCase().includes("dragnet")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/dragnet.svg`);
                    return true;
                }
                if (name?.toLowerCase().includes("nnpc")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/NNPC.svg`);
                    return true;
                }
                if (name?.toLowerCase().includes("nvidia")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/Nvidia.svg`);
                    return true;
                }
                if (name?.toLowerCase().includes("oracle")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/oracle.svg`);
                    return true;
                }
                if (name?.toLowerCase().includes("pwc")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/PWC.svg`);
                    return true;
                }
                if (name?.toLowerCase().includes("gtco")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/GTCO.svg`);
                    return true;
                }
                if (name?.toLowerCase().includes("zenithbank")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/ZenithBank.svg`);
                    return true;
                }

                if (name?.toLowerCase().includes("at&t")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/AT&T.svg`);
                    return true;
                }
                if (name?.toLowerCase().includes("amazon")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/amazon.svg`);
                    return true;
                }
                if (name?.toLowerCase().includes("aws")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/amazon.svg`);
                    return true;
                }
                if (name?.toLowerCase().includes("accenture")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/Accenture.svg`);
                    return true;
                }
                if (name?.toLowerCase().includes("adobe")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/Adobe.svg`);
                    return true;
                }
                if (name?.toLowerCase().includes("adp")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/ADP.svg`);
                    return true;
                }
                if (name?.toLowerCase().includes("aig")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/AIG.svg`);
                    return true;
                }
                if (name?.toLowerCase().includes("alcatel")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/alcatel.svg`);
                    return true;
                }
                if (name?.toLowerCase().includes("amdocs")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/amdocs.svg`);
                    return true;
                }
                if (name?.toLowerCase().includes("bayer")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/Bayer.svg`);
                    return true;
                }
                if (name?.toLowerCase().includes("blackrock")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/BlackRock.svg`);
                    return true;
                }
                if (name?.toLowerCase().includes("capgemini")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/Capgemini.svg`);
                    return true;
                }
                if (name?.toLowerCase().includes("cisco")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/Cisco.svg`);
                    return true;
                }
                if (name?.toLowerCase().includes("cognizant")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/Cognizant.svg`);
                    return true;
                }
                if (name?.toLowerCase().includes("cyient")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/Cyient.svg`);
                    return true;
                }
                if (name?.toLowerCase().includes("dell")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/Dell.svg`);
                    return true;
                }
                if (name?.toLowerCase().includes("deloitte")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/Deloitte.svg`);
                    return true;
                }
                if (name?.toLowerCase().includes("ericsson")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/Ericsson.svg`);
                    return true;
                }
                if (name?.toLowerCase().includes("sutherland")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/eversheds-sutherland.svg`);
                    return true;
                }
                if (name?.toLowerCase().includes("eversheds-sutherland")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/eversheds-sutherland.svg`);
                    return true;
                }
                if (name?.toLowerCase().includes("expedia")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/Expedia.svg`);
                    return true;
                }
                if (name?.toLowerCase().includes("google")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/Google.svg`);
                    return true;
                }
                if (name?.toLowerCase().includes("hackerrank")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/hackerrank.svg`);
                    return true;
                }
                if (name?.toLowerCase().includes("hcl")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/HCL_Technologies.svg`);
                    return true;
                }
                if (name?.toLowerCase().includes("hp")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/hp.svg`);
                    return true;
                }
                if (name?.toLowerCase().includes("hsbc")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/hsbc.svg`);
                    return true;
                }
                if (name?.toLowerCase().includes("huawei")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/Huawei.svg`);
                    return true;
                }
                if (name?.toLowerCase().includes("ibm")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/ibm.svg`);
                    return true;
                }
                if (name?.toLowerCase().includes("infosys")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/Infosys.svg`);
                    return true;
                }
                if (name?.toLowerCase().includes("intel")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/Intel.svg`);
                    return true;
                }
                if (name?.toLowerCase().includes("mindtree")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/mindtree.svg`);
                    return true;
                }
                if (name?.toLowerCase().includes("quest")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/QuestGlobal.svg`);
                    return true;
                }
                if (name?.toLowerCase().includes("global")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/QuestGlobal.svg`);
                    return true;
                }
                if (name?.toLowerCase().includes("reuters")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/reuters.svg`);
                    return true;
                }
                if (name?.toLowerCase().includes("sap")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/sap.svg`);
                    return true;
                }
                if (name?.toLowerCase().includes("sony")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/Sony.svg`);
                    return true;
                }
                if (name?.toLowerCase().includes("visa")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/Visa.svg`);
                    return true;
                }
                if (name?.toLowerCase().includes("vmware")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/VMware.svg`);
                    return true;
                }
                if (name?.toLowerCase().includes("wipro")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/Wipro.svg`);
                    return true;
                }
                if (name?.toLowerCase().includes("zte")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/zte.svg`);
                    return true;
                }
                if (name?.toLowerCase().includes("mckinsey")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/McKinsey.png`);
                    return true;
                }
                // Use a dynamic import to check if the image file exists
                this.imagePath = require(`@/assets/assessmentsLogos/aws.svg`);
                return true;
            } catch (error) {
                return false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.top-assessement {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background: #fff;
    border-radius: 10px;
    padding: 0.2rem;
    gap: 2rem;
    padding-bottom: 1rem;
    height: 300px;
    border-radius: 10px;
    background: #ffffff;
    transition: all 0.3s ease;

    // &:hover {
    //     height: 340px;
    //     // transform: scale(1.05);
    //     // .img-container {
    //     //     .hover-text {
    //     //         opacity: 1;
    //     //         background-color: rgba(255, 255, 255, 0.8);
    //     //     }
    //     // }
    //     .add-now {
    //         transition: all 0.3s ease;
    //         opacity: 1;
    //         // height: 50px;
    //         max-height: 50px;
    //     }
    //     .img-container {
    //         transition: all 0.3s ease;
    //         // opacity: 1;
    //         // max-height: 100px;
    //         margin-top: -5%;
    //     }
    //     .assessement-name {
    //         transition: all 0.3s ease;
    //         // opacity: 0;
    //         // max-height: 0;
    //         margin-top: -15%;
    //     }
    //     .assessement-infos {
    //         transition: all 0.3s ease;
    //         margin-top: -8%;
    //         opacity: 1;
    //         max-height: 110px;
    //     }
    // }

    .img-container {
        flex: 0 0 50%;
        width: 100%;
        height: 180px;
        position: relative;
        // overflow: hidden;
        // height: 100%;
        // padding: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.3s ease;
        img {
            width: 100%;
            border-radius: 10px;
            object-fit: fill;
            aspect-ratio: 3/2;
            object-fit: contain;
            width: 180px;
            height: 180px;
            // border-radius: 50%;
            // border: 2px solid #2195f352;
            padding: 5%;
        }

        .hover-text {
            opacity: 0;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            color: #656669;
            text-decoration: underline;
            /* background: rgba( 255,255,255, 0.8);*/
            background-color: transparent;
            border: none;
            font-size: 18px;
            font-weight: 400;
        }
    }

    > :nth-child(2) {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: space-between;
        flex: auto;
    }

    .assessement-name {
        width: 100%;
        font-size: 22px;
        line-height: 2rem;
        font-weight: 600;
        // padding: 0 1rem;
        text-align: center;
        transition: all 0.3s ease;
    }

    .assessement-infos {
        width: 100%;
        opacity: 0;
        max-height: 0;
        transition: all 0.3s ease;
        /*flex: 0 0 25%;*/
        ul {
            text-align: left;
            padding-left: 8%;
            list-style-type: disc;
            list-style: none;

            li {
                font-size: 16px;
                font-weight: 600;
                margin-bottom: 1%;
            }
        }
    }
}
.topAssess {
    // display: grid;
    // grid-template-rows: 1fr 2fr 1fr;
    padding: 0 2% -8% 2%;
}
.btnWrapper {
    // margin-top: 10%;

    .add-now {
        padding: 0.25rem;
        border-radius: 5px;
        color: #fff;
        background: #2196f3;
        border: none;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        width: 27%;
        place-self: center;
        height: 40px;
        border-radius: 10px;
        transition: all 0.3s ease;
    }
    .details {
        padding: 0.25rem;
        border-radius: 5px;
        color: #2196f3;
        background: #fff;
        border: 1px solid #2196f3;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        width: 30%;
        place-self: center;
        height: 40px;
        border-radius: 10px;
        transition: all 0.3s ease;
    }
}
.time {
    color: #000000b6;
}
.title {
    font-size: 18px;
    font-weight: 600;
    margin: 8% 0;
    margin-bottom: 5%;
    padding: 0 1rem;
    text-align: left;
    line-height: 1.2;
}
.assessDesc {
    font-size: 14px;
    font-weight: 400;
    padding: 0 1rem;
    margin: 5% 0;
    margin-bottom: 12%;
    text-align: left;
    line-height: 1.2;
}
</style>
